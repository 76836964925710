import React, { Fragment, useEffect, useState } from "react";
import { Root, Preview, Footer, GlobalStyle, TTButton } from "../styles";
import '../styles/font.css'
import { Link } from "react-router-dom";
import { Camera } from "../camera";

export const CheckMyEgg = () => {
    const [isCameraOpen, setIsCameraOpen] = useState(false);
    const [cardImage, setCardImage] = useState();

    useEffect(() => {
        if (!isCameraOpen) {
            setIsCameraOpen(true)
        }
    }, [])
    return (
        <Fragment>
            <Root>
                {/* <h1>{isCameraOpen ? 'camera open' : 'camera closed'}</h1>
                <h1>{cardImage ? 'image exists' : `image doesn't exist`}</h1> */}
                {isCameraOpen && !cardImage && (
                    <Camera
                        onCapture={(blob) => setCardImage(blob)}
                        onClear={() => setCardImage(undefined)}
                    />
                )}

                {cardImage && (
                    <div>
                        <p>I’m sorry, we can’t find a match.</p>

                        {/* <h2>Preview</h2> */}
                        <Preview src={cardImage && URL.createObjectURL(cardImage)} />
                    </div>
                )}

                {cardImage && (
                    <Link to="/">
                        <TTButton type="button">
                            Try Again
                        </TTButton>
                    </Link>
                )}

                {/* <Footer>
                    <button onClick={() => setIsCameraOpen(true)}>
                        Open Camera
                    </button>
                    <button
                        onClick={() => {
                            setIsCameraOpen(false);
                            setCardImage(undefined);
                        }}
                    >
                        Close Camera
                    </button>
                </Footer> */}
            </Root>
            <GlobalStyle />
        </Fragment>
    )
}