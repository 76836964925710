import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Camera } from "../camera";
import { Root, Preview, Footer, GlobalStyle, TTButton } from "../styles";
import '../styles/font.css'


export const Home = () => {

    return (
        <Fragment>
            <Root>
                <h1>Welcome</h1>
                <h2 style={{ marginTop: "3em" }}>Curious to know where your egg comes from?</h2>
                <Link style={{ marginTop: "3em" }} to={'/thisishow'}>
                    <TTButton>{`Next >`}
                    </TTButton>
                </Link>

            </Root>
            <GlobalStyle />
        </Fragment>
    )
}