import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Root, GlobalStyle, TTButton } from "../styles";
import '../styles/font.css'


export const ThisIsHow = () => {

    return (
        <Fragment>
            <Root>
                <h1>This is How</h1>
                <ol style={{ marginTop: "2em" }}>
                    <li>
                        Hold your egg to backlight (e.g. the sun or use another smartphone to do so)
                    </li>
                    <li>
                        Take a picture
                    </li>
                    <li>
                        Click on “check my egg”
                    </li>
                </ol>
                <Link style={{ marginTop: "3em" }} to={'/checkmyegg'}>
                    <TTButton>{`Got it >`}
                    </TTButton>
                </Link>
            </Root>
            <GlobalStyle />
        </Fragment>
    )
}