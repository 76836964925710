import React, { Fragment, useState } from "react";
import ReactDOM from "react-dom";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CheckMyEgg } from "./pages/CheckMyEgg";
import { Home } from "./pages/Home";
import { NotFound } from "./pages/NotFound";
import { ThisIsHow } from "./pages/ThisIsHow";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/thisishow" element={<ThisIsHow />} />
        <Route exact path="/notfound" element={<NotFound />} />
        <Route exact path="/checkmyegg" element={<CheckMyEgg />} />
      </Routes>
    </BrowserRouter>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
