import React, { Fragment } from "react";
import { Root, GlobalStyle } from "../styles";


export const NotFound = () => {

    return (
        <Fragment>
            <Root>


            </Root>
            <GlobalStyle />
        </Fragment>
    )
}